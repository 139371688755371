import React, {Component} from "react";
import FormGroup from "./FormGroup";
import {generateAndWrap} from "../crypto/Emk";
import {authenticate} from "./App";
import {Link} from "react-router-dom";
import "../css/SignUp.css";


const MIN_PASSWORD_LEN = 8;

class SignUp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: ""
        }
    }

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.submit();
    };

    submit = async () => {
        const username = this.state.username;
        const password = this.state.password;
        console.log("password: " + password);
        const {emk, salt, iterations, masterKey} = await generateAndWrap(password);
        // sign up
        const response = await fetch("/api/sign-up", {
            method: "POST",
            credentials: "same-origin",
            body: JSON.stringify({username: username}),
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        });
        const data = await response.json();
        let accountInfo = {
            emk,
            salt,
            iterations,
            cookie: data['cookie'],
        };
        console.log(accountInfo);
        localStorage.setItem("lastUser", username);
        localStorage.setItem(username, JSON.stringify(accountInfo));
        // log in
        authenticate().then(user => this.props.callback(user ? accountInfo : null, masterKey));
    };

    valid = () => this.state.username.length > 0 && this.state.password.length >= MIN_PASSWORD_LEN;

    render() {
        return (
            <form className="form sign-up" onSubmit={this.handleSubmit}>
                <h1>Sign Up</h1>
                <p>Please see the <Link className="navlink" to="/about"><span>About</span></Link> page if this is your first time.</p>
                <FormGroup label="Username">
                    <input ref={(input) => this.usernameInput = input} type="text" name="username" autoCapitalize="none" autoComplete="off" value={this.state.username} onChange={this.handleChange}/>
                </FormGroup>
                <FormGroup label="Password">
                    <input type="password" name="password" value={this.state.password} onChange={this.handleChange}/>
                </FormGroup>
                <div className="form-actions">
                    <FormGroup>
                        <input type="submit" className="btn btn-secondary" value="Create Account" disabled={!this.valid()}/>
                    </FormGroup>
                </div>
            </form>
        );
    }

}

export default SignUp;