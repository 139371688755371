import * as b64 from "base64-arraybuffer";

export const IV_LENGTH_BITS = 96;
export const IV_LENGTH_BYTES = IV_LENGTH_BITS/8;

export function ab2str(buf) {
    return String.fromCharCode.apply(null, new Uint16Array(buf));
}

export function str2ab(str) {
    const buf = new ArrayBuffer(str.length*2); // 2 bytes for each char
    const bufView = new Uint16Array(buf);
    for (let i=0, strLen=str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i);
    }
    return buf;
}

export async function encryptKey(masterKey, username, password) {

    console.log("Encrypting credentials");
    const plaintext = str2ab(JSON.stringify({u: username, pw: password}));
    const iv = crypto.getRandomValues(new Uint8Array(IV_LENGTH_BYTES));
    const encrypted = await crypto.subtle.encrypt({
        name: "AES-GCM",
        iv: iv,
    }, masterKey, plaintext);
    console.log(encrypted);
    return {
        alg: "A256GCM",
        iv: b64.encode(iv),
        k: b64.encode(encrypted)
    };

}

export async function decryptKey(masterKey, key) {

    console.log("Decrypting", key['k']);
    const encryptedBits = b64.decode(key['k']);
    const decryptedBits = await crypto.subtle.decrypt({
        name: "AES-GCM",
        iv: b64.decode(key['iv'])
    }, masterKey, encryptedBits);
    console.log("Decrypted");
    return JSON.parse(ab2str(decryptedBits));

}

export function suggestKey(len) {
    const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowercase = "abcdefghijklmnopqrstuvwxyz";
    const numbers = "0123456789";
    const symbols = "!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~";
    const all = (uppercase + lowercase + numbers + symbols).split("");
    let randomInts = new Uint8Array(len);
    crypto.getRandomValues(randomInts);
    randomInts = randomInts.map(i => i*all.length/255);
    return Array.from(randomInts, i => all[i]).join("");
}