import React, { Component } from "react";
import "../css/FormGroup.css";


class FormGroup extends Component {

    render() {

        const label = this.props.label ? <span className="form-label">{this.props.label}</span> : false;

        return (
            <div className={this.props.className ? "form-group " + this.props.className : "form-group"}>
                { label }
                <div className={this.props.action ? "action" : ""}>
                { this.props.children }
                </div>
            </div>
        )
    }

}

export default FormGroup;