import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import "../css/Navbar.css";

class Navbar extends Component {

    activeWithUser = match => {
        if (!this.props.user) {
            return true;
        }
        return match;
    };

    activeWithUserAndOnline = match => {
        if (!navigator.onLine) return true;
        if (!this.props.user) return true;
        return match;
    };

    render() {
        const login = this.props.user ? null : <NavLink to="/login"><span>Log In</span></NavLink>;
        const username = this.props.user ? <span>{this.props.user.name}</span> : null;
        const logout = this.props.user ? <button className="btn btn-logout" onClick={this.props.logout}><span>Log Out</span></button> : null;
        return (
            <div className="navbar">
                <div className="navbar-brand">
                    <Link to="/"><span className="navbar-title">{this.props.title}</span></Link>
                </div>
                <div className="navbar-links">
                    {username}
                    <NavLink exact to="/" isActive={this.activeWithUser}><span>Keys</span></NavLink>
                    <NavLink to="/devices"
                             isActive={this.activeWithUserAndOnline}><span>Devices</span></NavLink>
                    <NavLink exact to="/about"><span>About</span></NavLink>
                    {login}
                    {logout}
                </div>
            </div>
        )
    }

}

export default Navbar;