import React from "react";
import "../css/About.css";

const arrow = <svg width="10" height="40">
    <line className="arrow-line" x1="5" y1="0" x2="5" y2="30"/>
    <polygon className="arrow-head" points="0,30 5,40 10,30"/>
</svg>;

const arrowRight = <svg className="right" width="40" height="10">
    <line className="arrow-line" x1="0" y1="5" x2="30" y2="5"/>
    <polygon className="arrow-head" points="30,0 40,5 30,10"/>
</svg>;

export default function About(props) {

    return <div className="about">
        <h1>What is PassDB?</h1>
        <p>PassDB is an open source password manager that aims to combine the security of desktop password managers
        with the convenience of online ones.</p>
        <div className="img-container">
            <img src="/img/new-key.png" alt="Creating a new key"/>
        </div>
        <p>Using the Web Crypto API, PassDB encrypts your passwords locally before sending them to be
        stored online. This process ensures the security of your passwords—even if your network traffic is
        intercepted.</p>
        <div className="img-container">
            <img src="/img/network.png" alt="Encrypted network traffic"/>
        </div>
        <p>Most importantly, your master password is <span className="highlight">never</span> sent over the network.
        And it's not the only thing needed to decrypt your passwords: your randomly generated 256-bit
        <span className="highlight"> master key</span> must be obtained and decrypted through the device approval
        process, similar to 2FA. It is only stored, <span className="highlight">encrypted</span>, on your trusted
        devices.</p>
        <div className="img-container">
            <img src="/img/decrypt.png" alt="Entering the master password"/>
        </div>
        <h1>How Does It Work?</h1>
        <p>When you create an account, your password and a random salt are used to derive a fixed-length wrapper
        key on your device. This derivation is done using PBKDF2 with 100,000 iterations to make brute-force guessing
        passwords more difficult.</p>
        <div className="diagram">
            <Block title="Your Password" type="user"/>
            <span>+</span>
            <Block title="Salt" type="random" description={["Randomly Generated", "12 bytes"]}/>
            <br/>
            {arrow}
            <br/>
            <Block title="PBKDF2" type="function" description={["Iterations: 100k", "Hash Algorithm: SHA-256"]}/>
            <br/>
            {arrow}
            <br/>
            <Block title="Wrapper Key" type="wk" description={["AES-KW", "256 bits"]}/>
        </div>
        <p>This wrapper key is then used to encrypt your <span className="highlight">master key</span>.
        The master key is used for encrypting and decrypting your stored passwords, which is why
        it is generated randomly using the Web Crypto API rather than being based on your password.</p>
        <div className="diagram horizontal">
            <Block title="Master Key" type="random" description={["Randomly Generated", "256 bits"]}/>
            {arrowRight}
            <Block title="AES-KW" type="wk" description={["Algorithm: AES-256KW", "Encrypts Master Key"]}/>
            {arrowRight}
            <Block title="EMK" type="user" description={["Encrypted Master Key", "Stored on Your Device"]}/>
        </div>
        <p>The result of encrypting the master key with the AES-KW wrapper key is called the
        <span className="highlight"> EMK</span>, short for encrypted master key. This is what's stored on your device
        and decrypted locally when you log in.
        </p>
        <div className="img-container">
            <img src="/img/register-example.png" alt="An example of the device registration process"/>
        </div>
        <p>When you register a new device, your EMK from the existing device is sent to the server and pushed directly
        to the new device via a WebSocket. This is both for a smooth user experience and to prevent writing the EMK
        to disk or keeping it in memory on the server for an extended period, even though it is encrypted.
        It is <span className="highlight">highly recommended</span> to register at least one other device after signing
        up. That way, if one device is lost or damaged, the EMK can still be retrieved from the other device and you
        won't lose access to your account.</p>
        <p>With the EMK on your device and master password entered on login, you can retrieve and decrypt
        stored passwords without exposing sensitive information to the network, even if HTTPS fails. Anyone attempting
        to log in to your account will need device approval, your EMK, <span className="italic">and</span> your
        master password to gain access.
        </p>
    </div>

}

function Block(props) {

    const description = props.description ?
        <div className="block-description">
            {props.description.map((d, index) => <p key={index}>{d}</p>)}
        </div> : false;

    return <div className="block-container">
        <div className={"block " + props.type}>
            <span>{props.title}</span>
        </div>
        {description}
    </div>

}