import React, {Component} from "react";
import FormGroup from "./FormGroup";
import {Link, Redirect} from "react-router-dom";
import * as io from "socket.io-client";
import "../css/Form.css";
import "../css/Login.css";
import AuthorizeCard from "./AuthorizeCard";


class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: "",
            redirect: null,
            attempt_code: "",
            authorizing: false,
            auth_error: ""
        };
        this.socket = null;
        this.usernameInput = React.createRef();
    }

    callApi = async () => {
        const response = await fetch('/api/login', {
            method: "POST",
            credentials: "same-origin",
            body: JSON.stringify({
                username: this.state.username
            }),
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        });
        if (response.status === 401) {
            this.setState({auth_error: "User not found"});
            throw new Error("User not found");
        }
        return await response.json();
    };

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.submit();
    };

    configureSocket = () => {
        this.socket = io();
        this.socket.on("auth_success", data => {
            const accountInfo = JSON.parse(data);
            console.log("ACCOUNT INFO", accountInfo);
            const username = this.state.username;
            localStorage.setItem("lastUser", username);
            localStorage.setItem(username, JSON.stringify({
                cookie: accountInfo['cookie'],
                emk: accountInfo['emk'],
                salt: accountInfo['salt'],
                iterations: accountInfo['iterations']
            }));
            this.props.callback();
            this.setState({redirect: <Redirect to="/"/>});
        });
        this.socket.on("auth_error", () => this.setState({auth_error: "Authorization failed"}));
    };

    submit = () => {
        if (localStorage.getItem(this.state.username) !== null) {
            localStorage.setItem("lastUser", this.state.username);
            this.props.callback();
            return;
        }
        this.callApi()
            .then(res => {
                this.configureSocket();
                this.socket.emit("auth", res['attempt_id']);
                this.setState({
                    attempt_code: res['attempt_code'],
                    authorizing: true
                });
            })
            .catch(err => console.log(err));
    };

    valid = () => this.state.username.length > 0;

    componentDidMount() {
        this.usernameInput.current.focus();
    }

    componentWillUnmount() {
        if (this.socket) {
            this.socket.close();
        }
    }

    render() {
        const formContent = this.state.authorizing ?
            <div>
                <AuthorizeCard code={this.state.attempt_code}/>
            </div> :
            <form className="form" onSubmit={this.handleSubmit}>
                {this.state.redirect}
                <FormGroup label="Username">
                    <input type="text" name="username" autoCapitalize="none" autoComplete="off"
                           value={this.state.username} onChange={this.handleChange}
                           ref={this.usernameInput}/>
                </FormGroup>
                <div className="form-actions">
                    <FormGroup>
                        <input type="submit" className="btn btn-primary" value="Log In" disabled={!this.valid()}/>
                    </FormGroup>
                </div>
                <p className="separator">— or —</p>
                <FormGroup>
                    <Link className="link-button" to="/sign-up">
                        <input className="btn btn-secondary" type="button" value="Sign Up"/>
                    </Link>
                </FormGroup>
            </form>;
        const errorText = this.state.auth_error ? <h3>{this.state.auth_error}</h3> : null;
        return (
            <div className="login">
                <h1>PassDB</h1>
                {formContent}
                {errorText}
            </div>
        );
    }

}

export default Login;