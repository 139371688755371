import React, {Component} from "react";
import {Route, Switch, BrowserRouter as Router, Redirect} from "react-router-dom";
import Navbar from "./Navbar";
import Login from "./Login";
import SignUp from "./SignUp";
import Decrypt from "./Decrypt";
import KeyList from "./KeyList";
import Devices from "./Devices";
import About from "./About";
import "../css/App.css";

class App extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            user: null,
            masterKey: null,
            loading: true
        };
    }

    componentDidMount() {
        this.auth();
    }

    auth = () => {
        authenticate()
            .then(user => this.setState({user: user, loading: false}))
            .catch(() => this.setState({loading: false}));
    };

    logout = () => {
        localStorage.removeItem("lastUser");
        this.setState({user: null, masterKey: null});
    };

    render() {
        return (
            <Router>
            <div className="app">
                <Navbar title="PassDB" user={this.state.user} logout={this.logout}/>
                <div className="container">
                    <Switch>
                        <Route exact path="/" render={() => {
                            if (!this.state.user) {
                                return <Redirect to="/login"/>;
                            }
                            if (!this.state.masterKey) {
                                return <Redirect to="/decrypt"/>;
                            }
                            return <KeyList masterKey={this.state.masterKey}/>;
                        }}/>
                        <Route exact path="/login" render={() =>
                            this.state.user ? <Redirect to="/"/> : <Login callback={this.auth}/>
                        }/>
                        <Route exact path="/decrypt" render={() =>
                            this.state.masterKey || !this.state.user ?
                                <Redirect to="/"/> : <Decrypt user={this.state.user}
                                                              callback={key => this.setState({masterKey: key})}/>
                        }/>
                        <Route exact path="/sign-up" render={() =>
                            this.state.user ? <Redirect to="/"/> :
                                <SignUp callback={(user, masterKey) => this.setState({user: user, masterKey: masterKey})}/>
                        }/>
                        <Route exact path="/devices" render={() =>
                            this.state.user && this.state.masterKey ? <Devices user={this.state.user}/> : <Redirect to="/"/>
                        }/>
                        <Route exact path="/about" component={About}/>
                    </Switch>
                </div>
            </div>
            </Router>
        )
    }

}

export default App;

export async function authenticate() {

    console.log("Authenticating");
    const lastUser = localStorage.getItem("lastUser");
    if (!lastUser) {
        console.log("No last user");
        return null;
    }

    console.log("Loading account info");
    const accountInfo = JSON.parse(localStorage.getItem(lastUser));

    const cookie = accountInfo['cookie'];
    if (!cookie) {
        return null;
    }

    if (navigator.onLine) {
        console.log("Performing auth request");
        const response = await fetch("/api/auth", {
            method: "POST",
            credentials: "same-origin",
            body: JSON.stringify({cookie: cookie}),
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        });
        if (response.status !== 200) {
            return null;
        }
        const data = await response.json();
        console.log(data);

        accountInfo['cookie'] = data['cookie'];
        console.log("Account information", accountInfo);
        localStorage.setItem(lastUser, JSON.stringify(accountInfo));
    }

    return accountInfo;

}