import React, {Component} from "react";
import FormGroup from "./FormGroup";
import {loadMasterKey} from "../crypto/Emk";
import "../css/Decrypt.css";

class Decrypt extends Component {

    constructor(props) {
        super(props);
        this.state = {
            password: "",
            incorrect: false
        };
    }

    componentDidMount() {
        this.passwordInput.focus();
    }

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    handleSubmit = async event => {
        event.preventDefault();
        try {
            const masterKey = await loadMasterKey(this.props.user, this.state.password);
            console.log(masterKey);
            this.props.callback(masterKey);
        } catch(e) {
            console.log(e);
            this.setState({password: "", incorrect: true});
            setTimeout(() => this.setState({incorrect: false}), 750);
        }
    };

    valid = () => this.state.password.length > 0;

    render() {
        const passInputClass = this.state.incorrect ? "error" : "";
        return (
            <form className="form decrypt" onSubmit={this.handleSubmit}>
                <FormGroup label="Password">
                    <input className={passInputClass} ref={(input) => this.passwordInput = input} autoComplete="on"
                           type="password" name="password" value={this.state.password} onChange={this.handleChange}/>
                </FormGroup>
                <div className="form-actions">
                    <FormGroup>
                        <input type="submit" className="btn btn-primary" value="Decrypt" disabled={!this.valid()}/>
                    </FormGroup>
                </div>
            </form>
        )
    }

}

export default Decrypt;