import React, {Component} from "react";
import FormGroup from "./FormGroup";

class ActivateForm extends Component {

    handleChange = event => this.props.update({[event.target.name]: event.target.value});

    handleSubmit = event => {
        event.preventDefault();
        this.submit();
    };

    submit = () => {
        this.callApi()
            .then(res => {
                this.props.reload();
                this.props.close();
            })
            .catch(err => console.log(err));
    };

    valid = () => this.props.nickname && this.props.nickname.length > 0;

    callApi = async () => {
        const response  = await fetch("/api/client/activate", {
            method: "POST",
            credentials: "same-origin",
            body: JSON.stringify({
                attempt_id: this.props.attempt_id,
                nickname: this.props.nickname,
                emk: this.props.user.emk,
                salt: this.props.user.salt,
                iterations: this.props.user.iterations
            }),
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        });
        return await response.json();
    };

    render() {
        return (
            <form className="form">
                <h2>Activate Client</h2>
                <p>Browser: {this.props.browser}</p>
                <p>IP Address: {this.props.ip_address}</p>
                <p>Time: {this.props.time}</p>
                <FormGroup label="Nickname">
                    <input type="text" name="nickname" value={this.props.nickname} onChange={this.handleChange}/>
                </FormGroup>
                <div className="form-actions">
                    <FormGroup>
                        <input type="submit" className="btn btn-primary" value="Authorize" onClick={this.handleSubmit}
                        disabled={!this.valid()}/>
                    </FormGroup>
                </div>
            </form>
        )
    }


}

export default ActivateForm;