import React, {Component} from "react";
import ClientList from "./ClientList";
import FormGroup from "./FormGroup";
import ActivateForm from "./ActivateForm";
import Popup from "./Popup";


class Devices extends Component {

    constructor(props) {
        super(props);
        this.clientList = React.createRef();
        this.state = {
            loginCode: '',
            invalid_code: false,
            showActivate: false,
            selectedLogin: {
                attempt_id: "",
                nickname: "",
                browser: "",
                ip_address: "",
                time: ""
            }
        }
    }

    loadClients = () => this.clientList.current.loadClients();

    loadLogin = async () => {
        const response = await fetch('/api/login/search', {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                code: this.state.loginCode
            })
        });
        if (response.status === 404) {
            return null;
        }
        return await response.json();
    };

    handleChange = event => this.setState({[event.target.name]: event.target.value});

    handleSubmit = event => {
        event.preventDefault();
        this.loadLogin().then(res => {
            if (!res) {
                this.setState({loginCode: '', invalid_code: true});
                setTimeout(() => this.setState({invalid_code: false}), 750);
                return;
            }
            console.log(res);
            this.setState({
                loginCode: '',
                selectedLogin: {
                    attempt_id: res['id'],
                    nickname: '',
                    browser: res['browser'],
                    ip_address: res['ip_address'],
                    time: res['time']
                },
                showActivate: true
            })
        });
    };

    updateLogin = change => this.setState({selectedLogin: {...this.state.selectedLogin, ...change}});

    closeActivate = () => this.setState({showActivate: false});

    render() {
        const codeInputClass = this.state.invalid_code ? "uppercase error" : "uppercase";
        return (
            <div>
                <div className="container small">
                <form className="form" onSubmit={this.handleSubmit}>
                    <h2 className="text-center">New Client</h2>
                    <FormGroup label="Login Code" action={true}>
                        <input type="text" name="loginCode" value={this.state.loginCode} autoComplete="off"
                               className={codeInputClass} minLength={4} maxLength={4} onChange={this.handleChange}/>
                        <input type="submit" className="btn btn-primary btn-block" value="Submit"/>
                    </FormGroup>
                </form>
                </div>
                <Popup show={this.state.showActivate} close={this.closeActivate}>
                    <ActivateForm user={this.props.user} reload={this.loadClients} close={this.closeActivate}
                                  {...this.state.selectedLogin} update={this.updateLogin}/>
                </Popup>
                <h2 className="text-center">Clients</h2>
                <ClientList ref={this.clientList}/>
            </div>
        )
    }

}

export default Devices;