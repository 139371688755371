import React, {Component} from "react";
import moment from "moment";
import "../css/Client.css";

class Client extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fromNow: true
        }
    }

    onClientSelect = () => this.props.selectClient(this.props.id);

    showFromNow = () => this.setState({fromNow: true});

    showAbsolute = () => this.setState({fromNow: false});

    render() {

        const lastLogin = moment(this.props.last_login);
        const lastLoginString = this.state.fromNow ?
            lastLogin.fromNow() : lastLogin.format("MMM Do @ h:mm a");

        return (
            <div className="client"
                 onClick={this.onClientSelect}
                 onMouseEnter={this.showAbsolute}
                 onMouseLeave={this.showFromNow}>
                <div className="client-heading">
                    <h3>{this.props.nickname}</h3>
                    <p>Last Login: {lastLoginString}</p>
                </div>
            </div>
        )
    }

}

export default Client;