import React, {Component} from "react";


class Popup extends Component {

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef = node => this.wrapperRef = node;

    handleClickOutside = event => {
        if (this.props.show && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.props.close()
        }
    };

    render() {
        const classNames = this.props.show ? "popup-form" : "popup-form hidden";
        return (
            <div className={classNames} ref={this.setWrapperRef}>
                <button className="close-btn" onClick={this.props.close}>×</button>
                {this.props.children}
            </div>
        )
    }

}

export default Popup;