import React, {Component} from "react";
import FormGroup from "./FormGroup";
import {encryptKey, suggestKey} from "../crypto/Key";
import Slider from "./Slider";

const DEFAULT_STATE = {
    name: "",
    website: "",
    username: "",
    password: "",
    loading: false,
    generated: false,
    passLen: 12
};

class KeyForm extends Component {

    constructor(props) {
        super(props);
        this.state = DEFAULT_STATE;
        if (this.props.edit) {
            this.handleChange = event => this.props.update({[event.target.name]: event.target.value});
            this.valid = () => !this.state.loading &&
                this.props.name.length > 0 &&
                this.props.website.length > 0 &&
                this.props.username.length > 0 &&
                this.props.password.length > 0;
        }
        else {
            this.handleChange = event => this.setState({[event.target.name]: event.target.value});
            this.valid = () => !this.state.loading &&
                this.state.name.length > 0 &&
                this.state.website.length > 0 &&
                this.state.username.length > 0 &&
                this.state.password.length > 0;
        }
    }

    handlePasswordChange = event => {
        this.setState({generated: false});
        this.handleChange(event);
    };

    callApi = async (source, url) => {
        const encryptedKey = await encryptKey(this.props.masterKey, source.username, source.password);
        const response = await fetch(url, {
            method: "POST",
            credentials: "same-origin",
            body: JSON.stringify({
                name: source.name,
                website: source.website,
                content: JSON.stringify(encryptedKey)
            }),
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        });
        return await response.json();
    };

    callDeleteApi = async () => {
        const response = await fetch("/api/key/" + this.props.id + "/delete", {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        });
        return response.status;
    };

    handleSubmit = event => {
        event.preventDefault();
        this.submit();
    };

    submit = () => {
        this.setState({loading: true});
        let url, source;
        if (this.props.edit) {
            url = "/api/key/" + this.props.id;
            source = this.props;
        }
        else {
            url = "/api/key/new";
            source = this.state;
        }
        this.callApi(source, url)
            .then(res => {
                this.setState(DEFAULT_STATE);
                this.props.reload();
                this.props.close();
            })
    };

    deleteKey = () => {
        this.setState({loading: true});
        this.callDeleteApi()
            .then(res => {
                this.setState(DEFAULT_STATE);
                this.props.reload();
                this.props.close();
            })
            .catch(err => console.log(err));
    };

    generatePassword = () => {
        const suggestion = suggestKey(this.state.passLen);
        if (this.props.edit) {
            this.props.update({password: suggestion});
            this.setState({generated: true});
        }
        else {
            this.setState({password: suggestion, generated: true});
        }
    };

    handleLengthChange = event => this.setState({passLen: event.target.value}, this.generatePassword);

    render() {
        let source, del;
        if (this.props.edit) {
            source = this.props;
            del = <input type="button" className="btn btn-danger"
                       value="Delete" disabled={this.state.loading}
                       onClick={this.deleteKey}/>;
        }
        else {
            source = this.state;
        }
        const passwordType = this.state.generated ? "text" : "password";
        return (
            <form className="form" onSubmit={this.handleSubmit}>
                <FormGroup label="Name">
                    <input type="text" name="name" value={source.name} onChange={this.handleChange}/>
                </FormGroup>
                <FormGroup label="Website">
                    <input type="text" name="website" value={source.website} onChange={this.handleChange}
                           autoComplete="off" autoCorrect="off" spellCheck={false}/>
                </FormGroup>
                <FormGroup label="Username">
                    <input type="text" name="username" value={source.username} onChange={this.handleChange}/>
                </FormGroup>
                <FormGroup className="collapse" label="Password" action={true}>
                    <input type={passwordType} name="password" value={source.password}
                           autoComplete="off" onChange={this.handlePasswordChange}/>
                    <input type="button" className="btn btn-outline"
                           value="Generate" onClick={this.generatePassword}/>
                </FormGroup>
                <Slider min="6" max="24" initialValue={12} onChange={this.handleLengthChange}
                        title="Password Length:"/>
                <div className="form-actions">
                <FormGroup>
                    <div className="btn-group">
                        <input type="submit" className="btn btn-secondary" value="Save" disabled={!this.valid()} onClick={this.handleSubmit}/>
                        {del}
                    </div>
                </FormGroup>
                </div>
            </form>
        )
    }

}

export default KeyForm;