import React, {Component} from "react";
import {decryptKey} from "../crypto/Key";
import Popup from "./Popup";
import KeyForm from "./KeyForm";
import Key from "./Key";
import FormGroup from "./FormGroup";
import "../css/KeyList.css";


const PAGE_LEN = 6;

class KeyList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: "",
            page: 0,
            keys: [],
            showNew: false,
            showEdit: false,
            selectedKey: {
                name: "",
                website: "",
                username: "",
                password: ""
            }
        };
    }

    callApi = async () => {
        const response = await fetch("api/key", {
            method: "GET",
            credentials: "same-origin",
            headers: {
                "Accept": "application/json",
            }
        });
        return await response.json();
    };

    loadKeys = () => {
        this.callApi().then (async encryptedKeys => {
            console.time('Load all keys');
            const keys = await Promise.all(this.decryptKeys(encryptedKeys));
            console.timeEnd('Load all keys');
            this.setState({keys: keys});
        })
    };

    componentDidMount() {
        this.loadKeys();
    }

    editKey = id => {
        this.setState({selectedKey: this.state.keys.find(key => key['id'] === id), showEdit: true});
        window.scrollTo(0, 0);
    };

    closeNew = () => this.setState({showNew: false});

    closeEdit = () => this.setState({showEdit: false});

    updateKey = change => this.setState({selectedKey: {...this.state.selectedKey, ...change}});

    saveKey = () => {
        const index = this.state.keys.findIndex(key => key['id'] === this.state.selectedKey['id']);
        const newKeys = this.state.keys;
        newKeys[index] = this.state.selectedKey;
        this.setState({keys: newKeys});
    };

    decryptKeys = keys => keys.map(async key => {
        const decrypted = await decryptKey(this.props.masterKey, JSON.parse(key['content']));
        console.log(decrypted);
        key['username'] = decrypted['u'];
        key['password'] = decrypted['pw'];
        return key;
    });

    mapKeys = () => {
        const keys = this.state.search ?
            this.state.keys.filter(key => {
                const search = this.state.search.toLowerCase();
                return key.name.toLowerCase().startsWith(search) ||
                    key.website.toLowerCase().startsWith(search);
            }) : this.state.keys;
        const start = this.state.page * PAGE_LEN;
        return keys
            .slice(start, start + PAGE_LEN)
            .map(key =>
                <Key {...key} selectKey={this.editKey} key={key.id}/>)
    };

    isLastPage = () => this.state.page === Math.floor(this.state.keys.length / PAGE_LEN);

    updateSearch = event => {
        this.setState({search: event.target.value, page: 0})
    };

    nextPage = () => {
        if (!this.isLastPage()) {
            this.setState({page: this.state.page + 1});
        }
    };

    prevPage = () => {
        if (this.state.page > 0) {
            this.setState({page: this.state.page - 1});

        }
    };

    render() {
        const keys = this.mapKeys();
        return (
            <div className="key-list">
                <div className="key-list-heading">
                <input type="button" className="btn btn-primary" onClick={() => this.setState({showNew: true})} value="New Key"/>
                <form className="form">
                    <FormGroup>
                    <input type="text" placeholder="Search" value={this.state.search} onChange={this.updateSearch}/>
                    </FormGroup>
                </form>
                </div>
                <Popup show={this.state.showNew} close={this.closeNew}>
                    <KeyForm masterKey={this.props.masterKey} reload={this.loadKeys} close={this.closeNew}/>
                </Popup>
                <Popup show={this.state.showEdit} close={this.closeEdit}>
                    <KeyForm masterKey={this.props.masterKey} edit={true} {...this.state.selectedKey}
                             update={this.updateKey} close={this.closeEdit} save={this.saveKey}
                             reload={this.loadKeys}/>
                </Popup>
                <div className="key-container">
                {keys}
                </div>
                <div className="nav-buttons">
                    <input type="button" className="btn-outline" value="Next" onClick={this.nextPage} disabled={this.isLastPage()}/>
                    <input type="button" className="btn-outline" value="Prev" onClick={this.prevPage} disabled={this.state.page === 0}/>
                </div>
            </div>
        )
    }

}

export default KeyList;