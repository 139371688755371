import React, {Component} from "react";
import FormGroup from "./FormGroup";
import "../css/Form.css";

class ClientForm extends Component {

    handleChange = event => this.props.update({[event.target.name]: event.target.value});

    handleSubmit = event => {
        event.preventDefault();
        this.submit();
    };

    submit = () => {
        this.callApi()
            .then(res => {
                this.props.reload();
                this.props.close();
            })
            .catch(err => console.log(err));
    };

    deleteClient = () => {
        this.callDeleteApi()
            .then(status => {
                console.log("Deuathorization successful");
                console.log("Reloading...");
                this.props.reload();
                console.log("Closing...");
                this.props.close();
            })
            .catch(err => console.log(err));
    };

    callApi = async () => {
        const response = await fetch("/api/client/" + this.props.id, {
            method: "POST",
            credentials: "same-origin",
            body: JSON.stringify({
                nickname: this.props.nickname
            }),
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        });
        return await response.json();
    };

    callDeleteApi = async () => {
        const response = await fetch("/api/client/" + this.props.id + "/delete", {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        });
        return response.status;
    };

    render() {
        return (
            <form className="form" onSubmit={this.handleSubmit}>
                <h2>Edit Client</h2>
                <FormGroup label="Nickname">
                    <input type="text" name="nickname" value={this.props.nickname} onChange={this.handleChange}/>
                </FormGroup>
                <FormGroup label="Browser">
                    <input type="text" name="browser" value={this.props.browser} readOnly={true}/>
                </FormGroup>
                <div className="form-actions">
                    <FormGroup>
                        <input type="submit" className="btn btn-secondary" value="Save"/>
                    </FormGroup>
                    <FormGroup>
                        <input type="button" className="btn btn-danger" value="De-authorize" onClick={this.deleteClient}/>
                    </FormGroup>
                </div>
            </form>
        )
    }

}

export default ClientForm;