import React, {Component} from "react"
import "../css/AuthorizeCard.css";

class AuthorizeCard extends Component {

    render() {
        return (
            <div className="authorize-card">
                <div className="header">
                    <span>Login Code:</span>
                    <span className="code">{this.props.code}</span>
                </div>
                <div className="body">
                    <p>On a registered device, navigate to the devices tab.</p>
                    <p>Then, enter the code above to approve this device.</p>
                </div>
            </div>
        )
    }
}

export default AuthorizeCard;