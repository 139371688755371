import React, {Component} from "react";
import "../css/Key.css";


class Key extends Component {

    onKeySelect = () => this.props.selectKey(this.props.id);

    render() {
        return (
            <div className="key">
                <div className="key-heading" onClick={this.onKeySelect}>
                    <h4>{this.props.name}</h4>
                    <a href={"https://" + this.props.website}
                       target="_blank" rel="noopener noreferrer">{this.props.website}</a>
                </div>
                <div className="key-body">
                    <p>{this.props.username}</p>
                    <span>{this.props.password}</span>
                </div>
            </div>
        )
    }

}

export default Key;