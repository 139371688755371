import React, { useState } from "react";
import "../css/Slider.css"

export default function Silder(props) {

    const [val, setVal] = useState(props.initialValue);

    const onChange = event => {
        setVal(event.target.value);
        props.onChange(event);
    };

    return <div className="slider">
        <input type="range" value={val} min={props.min} max={props.max} onChange={onChange}/>
        <p>{props.title} {val}</p>
    </div>

}