import React, {Component} from "react";
import Client from "./Client";
import Popup from "./Popup";
import ClientForm from "./ClientForm";
import "../css/ClientList.css";

class ClientList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clients: [],
            showEdit: false,
            selectedClient: {
                nickname: "",
                browser: ""
            }
        };
    }

    callApi = async () => {
        const response = await fetch("/api/client", {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        });
        return await response.json();
    };

    loadClients = () => this.callApi().then(res => this.setState({clients: res}));

    componentDidMount() {
        this.loadClients();
    }

    editClient = id => {
        this.setState({selectedClient: this.state.clients.find(c => c['id'] === id), showEdit: true});
        window.scrollTo(0, 0);
    };

    closeEdit = () => this.setState({showEdit: false});

    updateClient = change => this.setState({selectedClient: {...this.state.selectedClient, ...change}});

    saveClient = () => {
        const index = this.state.clients.findIndex(c => c['id'] === this.state.selectedClient['id']);
        const newClients = this.state.clients;
        newClients[index] = this.state.selectedClient;
        this.setState({clients: newClients});
    };

    render() {
        const clients = this.state.clients.map(c =>
            <Client id={c['id']} key={c['id']} nickname={c['nickname']}
            browser={c['browser']} last_login={c['last_login']} selectClient={this.editClient}/>
        );
        return (
            <div className="client-list">
                <Popup show={this.state.showEdit} close={this.closeEdit}>
                    <ClientForm update={this.updateClient} save={this.saveClient} reload={this.loadClients}
                                close={this.closeEdit} {...this.state.selectedClient}/>
                </Popup>
                <div className="client-container">
                    {clients}
                </div>
            </div>
        )
    }

}

export default ClientList;