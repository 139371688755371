import * as b64 from "base64-arraybuffer";
import {IV_LENGTH_BYTES} from "./Key";

const KEY_LENGTH_BITS = 256;
const PBKDF2_ITERATIONS = 100000;

export async function deriveWrapperKey(password, saltBuffer, iterations) {

    console.log("Using password:", password);
    console.log("and salt:", saltBuffer);

    // Encode password and salt
    const textEncoder = new TextEncoder();
    const passwordBuffer = textEncoder.encode(password);

    // Import the password
    console.log("Importing password as wrapper key");
    const importedKey = await crypto.subtle.importKey("raw", passwordBuffer, {name: "PBKDF2"},
        false,  ["deriveKey"]);

    // Derive a 256-bit key from the password
    return await crypto.subtle.deriveKey({
        name: "PBKDF2",
        hash: "SHA-256",
        salt: saltBuffer,
        iterations
    }, importedKey, {
        name: "AES-KW",
        length: KEY_LENGTH_BITS
    }, false, ["wrapKey", "unwrapKey"]);

}

export async function generateMasterKey() {

    return await crypto.subtle.generateKey({
        name: "AES-GCM",
        length: 256,
    },true,["encrypt", "decrypt"]);

}

export async function generateAndWrap(password) {

    const saltBuffer = crypto.getRandomValues(new Uint8Array(IV_LENGTH_BYTES));
    console.log("Generated salt");

    const wrapperKey = await deriveWrapperKey(password, saltBuffer, PBKDF2_ITERATIONS);
    console.log("Derived wrapper key from password");

    const masterKey = await generateMasterKey();
    console.log("Generated master key");

    const emk = await wrapMasterKey(masterKey, wrapperKey);
    console.log("Encrypted master key with wrapper key");

    return {
        emk: emk,
        salt: b64.encode(saltBuffer),
        iterations: PBKDF2_ITERATIONS,
        masterKey: masterKey,
    }

}

export async function wrapMasterKey(masterKey, wrapperKey) {

    const encryptedBits = await crypto.subtle.wrapKey("raw", masterKey, wrapperKey,
    {
        name: "AES-KW"
    });
    return {
        alg: "A256KW",
        k: b64.encode(encryptedBits),
    };

}

export async function unwrapMasterKey(emk, wrapperKey) {

    return await crypto.subtle.unwrapKey("raw", b64.decode(emk['k']), wrapperKey,
    {
        name: "AES-KW"
    },
    {
        name: "AES-GCM",
        length: 256
    }, true, ["encrypt", "decrypt"]);

}

export async function loadMasterKey(user, password) {

    console.log("Loading master key...");
    const wrapperKey = await deriveWrapperKey(password, b64.decode(user.salt), user.iterations);
    console.log(wrapperKey);
    return await unwrapMasterKey(user['emk'], wrapperKey);

}